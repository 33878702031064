

































import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import { callAppFunc_getSomeParams, appFunc_callExit } from '@/utils/bridge'

@Component({
  name: 'About'
})
export default class About extends Vue {
  // app版本
  appVersion = ''

  async mounted() {
    await this.getSomeData()
  }

  async getSomeData() {
    const res = await callAppFunc_getSomeParams()
    console.log(res, 'getSomeData', '////////////////////////////')
    CommonModule.setAppVersion(res.versionName)
    this.appVersion = CommonModule.appVersion
  }

  // 当前模式
  get mode() {
    const data = this.$route.query.backgroundMode as string
    return data || 'light'
  }
  // 当前语言
  get language() {
    return this.$i18n.locale
  }
  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language || 'ug'
  }
  handelback() {
    appFunc_callExit()
  }
}
